<script>
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import CoreApi from '@/api/core'

export default {
  name: 'OtherFunctionSearchSelect',
  extends: AuthorSearchSelect,
  props: {
    label: {
      type: String,
      required: true
    },
    selectedAuthorPosition: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      title: this.label
    }
  },
  methods: {
    // overriding from AuthorSearchSelect.vue
    getItemName (item) {
      return item?.name?.trim() ?? ''
    },
    // overriding from AuthorSearchSelect.vue
    async fetchItems (query) {
      const filterQuery = query ? `&filter_contains[name]=${query}` : ''
      const responseFunctions = await CoreApi().get(`/authorOtherFunction?limit=${this.limit}${filterQuery}`)

      if (this.selectedAuthorPosition) {
        const dataWithoutAuthorPosition = responseFunctions.data.data.filter(x => x.name !== this.selectedAuthorPosition)
        return dataWithoutAuthorPosition
      }

      return responseFunctions.data.data
    }
  }
}
</script>
